<template>
  <a-modal
    title="添加储值卡关联商品"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="ok"
    @cancel="() => { $emit('cancel') }"
  >
    <div :bordered="false">
      <div>
        <div class="table-page-search-wrapper">
          <a-form layout="inline" :form="form">
            <a-row v-if="inputBatchVisible" >
              <a-col :md="24" :sm="24">
                <a-form-item label="储值卡批次">
                  <a-input v-model="batch" placeholder="储值卡批次" style="width: 80%" v-decorator="['batch', {rules: [{ required: true, message: '请输入'}]}]"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="48" v-if="false">
              <a-col :md="8" :sm="24">
                <a-form-item label="商品标签">
                  <a-select
                    mode="tags"
                    placeholder="选择标签"
                    @change="handleTagsChange"
                  >
                    <a-select-option v-for="(tag, index) in allTags" :key="index" :value="tag.id + ''">
                      {{ tag.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="商品名称">
                  <a-input v-model="queryParam.brief" allow-clear placeholder="请输入商品名称" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <div class="table-operator">
                  <a-button type="primary" icon="search" @click="$refs.table.refresh(true)">查找</a-button>
                </div>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 查询结果 -->
        <a-alert :showIcon="false" style="margin-bottom: 16px" type="error">
          <template slot="message">
            <span style="margin-right: 12px">已选择: <a style="font-weight: 600">{{ this.selectedRowKeys.length }}</a></span>
            <a style="margin-left: 24px" @click="clear" :disabled="!hasSelected" v-if="hasSelected">清空</a>
          </template>
        </a-alert>
        <div class="table-page-search-wrapper">
          <a-form layout="inline" :form="form">
            <a-row :gutter="48" v-if="true">
              <a-col :md="6" :sm="24">
                <a-form-item label="商品名称">
                  <a-input v-model="queryParam.brief" allow-clear placeholder="请输入商品名称" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="商品标签">
                  <a-select
                    mode="tags"
                    placeholder="选择标签"
                    @change="handleTagsChange"
                  >
                    <a-select-option v-for="(tag, index) in allTags" :key="index" :value="tag.id + ''">
                      {{ tag.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <div class="table-operator">
                  <a-button type="primary" icon="search" @click="$refs.table.refresh(true)">查找</a-button>
                </div>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          ref="table"
          size="small"
          :columns="columns"
          :pageNum="pageNum"
          :data="loadData"
          :row-selection="rowSelection"
          rowKey="id">

          <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
          <a-popover title="标签" slot="tags" slot-scope="tags">
            <template slot="content">
              <p v-for="tag in tags" :key="tag.id" style="color: red">{{ tag.name }}</p>
            </template>
            <span>
              <a-tag
                v-for="tag in tags"
                :key="tag.id"
                color="red">
                {{ tag.name }}
              </a-tag>
            </span>
          </a-popover>
          <div slot="price_render" slot-scope="text">
            {{ text / 100 | numberFormat }}
          </div>
          <div slot="show_on_index" slot-scope="text">
            <a-switch :checked="text"/>
          </div>
          <div slot="is_sale" slot-scope="text">
            <a-switch :checked="text"/>
          </div>
          <div slot="is_new" slot-scope="text">
            <a-switch :checked="text"/>
          </div>
          <div slot="is_hot" slot-scope="text">
            <a-switch :checked="text"/>
          </div>
        </s-table>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { goods_tag_list, listGoods } from '@/api/applet_goods'
import { STable } from '@/components'

export default {
  name: 'ValueCardBindGoodsForm',
  components: {
    STable
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    inputBatchVisible: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],
      pageNum: 1,
      goodsList: [],
      goodsIdsList: [],
      allTags: [],
      batch: undefined,
      columns: [
        {
          title: '商品名称',
          dataIndex: 'brief',
          width: 150,
          ellipsis: true
        },
        {
          title: '标签',
          dataIndex: 'tags',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'tags' }
        },
        {
          title: '当前价',
          dataIndex: 'price',
          align: 'center',
          width: '150px',
          scopedSlots: { customRender: 'price_render' }
        }
      ],
      loadData: parameter => {
        return listGoods(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data)
            const entries = res.data.entries.filter(item => {
              return this.goodsIdsList.indexOf(item.id) < 0
            })
            if (entries.length > 0) {
              this.goodsList.push(...entries)
              this.goodsIdsList.push(...entries.map(item => item.id))
            }
            return res.data
          })
      }
    }
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    },
    rowSelection () {
      const that = this
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          that.onSelectChange(selectedRowKeys, selectedRows)
        },
        selectedRowKeys: this.selectedRowKeys,
        selectedRows: this.selectedRows
      }
    }
  },
  mounted () {
    this.loadAllTags()
  },
  methods: {
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    clear () {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    ok () {
      let selectedGoodsList = this.goodsList.filter(item => this.selectedRowKeys.indexOf(item.id) > -1)
      if (!this.inputBatchVisible) {
        this.$emit('ok', selectedGoodsList)
      } else {
        if (this.batch === undefined) {
          this.$message.warning('输入储值卡批次')
          return
        }
        if (selectedGoodsList.length === 0) {
          this.$message.warning('选择商品')
          return
        }
        selectedGoodsList = selectedGoodsList.map(item => item.id)
        this.$emit('ok', { goods_ids: selectedGoodsList, batch: this.batch })
      }
    },
    loadAllTags () {
      goods_tag_list().then((res) => {
        this.allTags = res.data.entries || []
      })
    },
    handleTagsChange (value) {
      console.log(value)
      this.queryParam.tag_ids = value.join(',')
    }
  }
}
</script>

<style scoped>
/deep/.ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 5px;
  margin-bottom: -3px;
  height: auto;
  max-height: 30px;
  max-width: 200px;
  overflow: auto;
  overflow-y: hidden;
}
/deep/.ant-select-selection--multiple .ant-select-selection__choice {
  overflow: initial;
}
/deep/.ant-select ul,
.ant-select ol {
  display: flex;
}
/deep/.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  margin-top: 3px;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  width: auto;
  max-height: 100%;
}
/deep/.ant-select-search--inline .ant-select-search__field__wrap{
  max-width: 0px !important;
}
/deep/.ant-select-selection__rendered::-webkit-scrollbar {
  /*滚动条整体样式*/
  height: 3px;
}
/deep/.ant-select-selection__rendered::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: lightcoral;
}
/deep/.ant-select-selection__rendered::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
  border-radius: 10px;
  background: #ededed;
}
</style>
