<template>
  <a-drawer
    :title="'储值卡批次【' + model.batch + '】关联商品'"
    placement="right"
    :visible="visible"
    width="850"
    :closable="true"
    @close="() => { $emit('cancel') }"
  >
    <a-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="bingGoods"
      :pagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="price_render" slot-scope="text" style="text-align: left; min-width: 90px; max-width: 240px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <template slot="action" slot-scope="text, record">
        <a @click="handleCancelBind(record)">取消关联</a>
      </template>
    </a-table>
  </a-drawer>
</template>
<script>

import { deleteValueCardBindGoods, getValueCardBindGoods } from '@/api/shop_value_card'
import { STable } from '@/components'
export default {
  name: 'ValueCardConsumeRecordList',
  components: {
    STable
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品名称',
          ellipsis: true,
          dataIndex: 'brief'
        },
        {
          title: '条形码',
          ellipsis: true,
          dataIndex: 'barcode'
        },
        {
          title: '供应商',
          ellipsis: true,
          dataIndex: 'supplier'
        },
        {
          title: '是否在售',
          ellipsis: true,
          align: 'center',
          dataIndex: 'goods_is_sale',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: '120px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      pageNum: 1,
      bingGoods: [],
      queryParam: {}
    }
  },
  created () {
    this.queryParam.batch = this.model.batch
    this.getGoods()
  },
  methods: {
    getGoods () {
      getValueCardBindGoods(this.queryParam)
        .then(({ data }) => {
          this.loading = false
          this.bingGoods = data
          // return { entries: data }
        }).finally(() => {
        this.loading = false
      })
    },
    handleCancelBind (record) {
      console.log(record)
      deleteValueCardBindGoods(record.id).then(res => {
        if (res.code === 1000) {
          this.getGoods()
        }
      })
    }
  }
}
</script>
<style>
</style>
