<template>
  <a-modal
    title="新建储值卡"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok', this.goodsIdList) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="开始卡号" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input style="width: 100%;" :maxLength="20" v-decorator="['card_no_start', {rules: [{ required: true, message: '请输入', pattern: /^\d+$/ }]}]" @change="cardNoStartChange"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="结束卡号" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input style="width: 100%;" :maxLength="20" v-decorator="['card_no_end', {rules: [{required: true, message: '请输入', pattern: /^\d+$/}]}]" @change="cardNoEndChange"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="批次" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['batch', {rules: [{ required: true, message: '请输入' }]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input-number :min="0" v-decorator="['value', {rules: [{required: true, message: '请输入'}]}]"/>
              <span style="padding-left: 10px;">元</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select
                v-decorator="[
                  'type',
                  {
                    initialValue: '1',
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                @change="valueCardTypeChange"
                placeholder="类型">
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.value_card_type">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="数量" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input-number disabled :min="1" v-decorator="['count', {rules: [{ required: true, message: '请输入'}]}]" />
              <span style="padding-left: 10px;">张</span>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="this.valueCardType === 2">
            <a-form-item label="商品" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-button icon="plus" @click="addGoods">添加</a-button>
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="this.goodsList.length > 0 && this.valueCardType === 2">
            <a-table bordered :data-source="goodsList" :columns="goodsColumns" :pagination="false" rowKey="id">
              <template slot="action" slot-scope="text, record">
                <a-popconfirm
                  v-if="goodsList.length"
                  title="确定删除?"
                  @confirm="() => onGoodsDelete(record.key)"
                >
                  <a href="javascript:;">删除</a>
                </a-popconfirm>
              </template>
            </a-table>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <goods-list-form
      ref="goodsListForm"
      @cancel="handleAddCancel"
      @ok="handleOk"
      :record="mdl"
      :visible="goodsVisible"
      v-if="goodsVisible"
      :loading="goodsLoading"
      :model="mdl" />
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import moment from 'moment/moment'
import GoodsListForm from './GoodsListForm'

const fields = ['card_no_start', 'card_no_end', 'count', 'value', 'batch']
export default {
  name: 'CreateValueCardForm',
  components: {
    GoodsListForm
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      value_card_type: {
        1: '通用',
        2: '商品专用'
      },
      valueCardType: 0,
      form: this.$form.createForm(this),
      goodsList: [],
      goodsVisible: false,
      goodsLoading: false,
      mdl: {},
      goodsColumns: [
        {
          title: '名称',
          dataIndex: 'brief'
        },
        {
          title: '条形码',
          dataIndex: 'barcode'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.model !== null) {
        this.loadEditInfo(this.model)
      }
      this.handleGenerateBatch()
    })
  },
  computed: {
    goodsIdList () {
      return this.goodsList.map(item => {
        return item.id
      })
    }
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      form.setFieldsValue(formData)
    },
    handleGenerateBatch () {
      const today = moment()
      const year = today.year()
      const month = today.month() + 1 // 注意月份从0开始，所以要加1
      const date = today.date() > 9 ? today.date() : '0' + today.date()
      console.log(date)
      this.form.setFieldsValue({
        batch: '' + year + month + date
      })
    },
    countCommonStart (str1, str2) {
      const length = Math.min(str1.length, str2.length)
      for (let i = 0; i < length; i++) {
        if (str1[i] !== str2[i]) {
          return i
        }
      }
      return length
    },
    cardNoStartChange (e) {
      const end_no = this.form.getFieldValue('card_no_end')
      const start_no = e.target.value
      if (start_no === undefined || end_no === undefined) {
        this.form.setFieldsValue({
          count: undefined
        })
        return
      }
      if (end_no.length !== start_no.length) {
        this.form.setFieldsValue({
          count: undefined
        })
        return
      }
      const equalIndex = this.countCommonStart(start_no, end_no)
      const count = Number(end_no.substring(equalIndex)) - Number(start_no.substring(equalIndex))
      if (count < 0) {
        this.form.setFieldsValue({
          count: undefined
        })
        return
      }
      this.form.setFieldsValue({
        count: count + 1
      })
    },
    cardNoEndChange (value) {
      const start_no = this.form.getFieldValue('card_no_start')
      const end_no = value.target.value
      if (start_no === undefined || end_no === undefined) {
        this.form.setFieldsValue({
          count: undefined
        })
        return
      }
      if (end_no.length !== start_no.length) {
        this.form.setFieldsValue({
          count: undefined
        })
        return
      }
      const equalIndex = this.countCommonStart(start_no, end_no)
      const count = Number(end_no.substring(equalIndex)) - Number(start_no.substring(equalIndex))
      if (count < 0) {
        this.form.setFieldsValue({
          count: undefined
        })
        return
      }
      this.form.setFieldsValue({
        count: count + 1
      })
    },
    valueCardTypeChange (value) {
      this.valueCardType = Number(value)
    },
    addGoods () {
      this.goodsVisible = true
    },
    handleAddCancel () {
      this.goodsVisible = false
      const form = this.$refs.goodsListForm.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk (data) {
      this.goodsVisible = false
      console.log('返回的数据', data)
      data = data.filter(item => !this.goodsIdList.includes(item.id))
      console.log('返回的数据', data)
      this.goodsList.push(...data)
    },
    onGoodsDelete (key) {
      const dataSource = [...this.goodsList]
      this.goodsList = dataSource.filter(item => item.key !== key)
    }
  }
}
</script>
